<template>
  <div class="demo-drawer__content" v-loading="loading">
    <div class="containerLeft">
      <div class="scheduleTitle">
        <el-input 
          v-model="directoryKeyword" 
          size="small" 
          suffix-icon="el-icon-search"
          :placeholder="$t('dashboardCapacityMonit.Search')" 
        />
        <toolTipIcon 
          :icon="`iconfont ${!isCollect ? 'icon-shoucang' : 'icon-yishoucang'}`" 
          :tooltipText="!isCollect ? $t('home.AlreadyFollow') : $t('home.Nofollow')"
          @click="searchCollect"
        />
      </div>
      <div class="directoryList">
        <template v-if="searchDirectoryList.length">
          <div 
            class="item" 
            :class="directoryActive == item.mailTemplateId ? 'active' : ''"
            v-for="item in searchDirectoryList" 
            :key="item.mailTemplateId"
            @click="jump(item, item.mailTemplateId)">
            <img src="https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/%E9%82%AE%E4%BB%B6%20%283%29%201.png" />
            <div class="content">
              <div class="name" v-showTootip>{{ item.mailTemplateName }}</div>
              <div class="totalMember">{{ item.number || 0 }}</div>
            </div>
          </div>
        </template>
        <el-empty 
          v-else 
          :description="$t('overallSituation.noData')" 
          :image="$emptyImage"
          :image-size="90"
        />
      </div>
    </div>
    <div class="containerRight">
      <template v-if="templateList.length">
        <div class="templateList">
          <div 
            class="item" 
            v-for="(item, index) in templateList" 
            :key="index">
            <div class="header">
              <div class="title">
                <div class="index">{{ index+1 }}</div>
                <div class="text" v-showTootip>{{ item.mailTemplateName }}</div>
              </div>
              <div class="icons">
                <toolTipIcon 
                  :icon="`iconfont ${!item.collect ? 'icon-shoucang' : 'icon-yishoucang'}`" 
                  :tooltipText="!item.collect ? $t('home.Follow') : $t('home.Unfollow')"
                  background="#ffffff"
                  @click="collectEvent(item)"
                />
                <toolTipIcon 
                  icon="iconfont icon-a-fanyi1" 
                  background="#ffffff"
                  :tooltipText="$t('home.Translate')"
                  @click="item.isInterpret = !item.isInterpret"
                />
              </div>
            </div>
            <div class="content">
              <div 
                class="box ql-editor" 
                :class="!item.isInterpret ? 'noBorder' : ''" 
                v-html="item.mailTemplateContentEn"></div>
              <div 
                class="box ql-editor" 
                v-if="item.isInterpret" 
                v-html="item.mailTemplateContentCn"></div>
            </div>
            <div class="footer">
              <el-button type="primary" size="small" @click="copy(item)">复制</el-button>
            </div>
          </div>
        </div>
      </template>
      <el-empty 
        v-else 
        :description="$t('overallSituation.noData')" 
        :image="$emptyImage"
        :image-size="90"
      />
    </div>
  </div>
</template>

<script>
import { getEmailTemplateDirectoryList, collectEmailTemplate } from "@/api/common";
export default {
  props: {
    keyword: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      directoryActive: null,
      directoryList: [],
      directoryKeyword: "",
      templateList: [],
      isCollect: 0
    }
  },
  mounted() {
    this.getDirectoryList();
  },
  computed: {
    searchDirectoryList() {
      return this.directoryList.filter(row => {
        return row.mailTemplateName.toLowerCase().indexOf(this.directoryKeyword.toLowerCase()) !== -1;
      })
    }
  },
  watch: {
    keyword(val) {
      if(val) {
        this.getDirectoryList(null, val);
      } else {
        this.getDirectoryList();
      }
    }
  },
  methods: {
    getDirectoryList(active, keyword) {
      getEmailTemplateDirectoryList({
        collect: this.isCollect
      }).then((data) => {
        this.directoryList = data;
        if(keyword) {
          this.directoryList = this.directoryList.filter(item => item.mailTemplateId == keyword.mailTemplateParentId);
          this.directoryList = this.directoryList.map(item => {
            return {
              ...item,
              children: item.children.filter(row => row.mailTemplateId == keyword.mailTemplateId)
            }
          })
        }
        if(this.directoryList.length) {
          this.jump(!active ? this.directoryList[0] : this.directoryList.find(item => item.mailTemplateId == active), !active ? this.directoryList[0].mailTemplateId : active);
        }
      })
    },
    searchCollect() {
      this.isCollect = !this.isCollect ? 1 : 0;
      this.getDirectoryList();
    },
    collectEvent(row) {
      if(row.collect == 1) {
        this.$confirm(
          this.$t('Are you sure to cancel the collection'), 
          this.$t("Tips"),
          {
            confirmButtonText: this.$t('Confirm'),
            cancelButtonText: this.$t('Cancel'),
            type: 'warning'
          }
        ).then(() => {
          collectEmailTemplate(row.mailTemplateId).then(() => {
            this.getDirectoryList(this.directoryActive);
          })
        }).catch(() => {});
        return;
      }
      collectEmailTemplate(row.mailTemplateId).then(() => {
        this.getDirectoryList(this.directoryActive);
      })
    },
    jump(row, active) {
      this.directoryActive = active;
      this.templateList = row.children.map(item => ({
        ...item,
        isInterpret: false
      }));
    },
    copy(row) {
      const theClipboard = navigator.clipboard;
      theClipboard.writeText(row.mailTemplateContentEn).then(() => {
        this.$message.success(this.$t('home.Copysucceeded'));
        document.body.click();
      });
    }
  },
}
</script>

<style lang="less" scoped>
.scheduleTitle {
  display: flex;
  gap: 20px;
  .el-input {
    flex: 1;
  }
  .collectIcon {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /deep/.box {
    i {
      &.icon-yishoucang {
        color: #DF8F31;
      }
    }
  }
}
.directoryList {
  width: 100%;
  overflow: overlay;
  height: calc(100% - 62px);
  .item {
    padding: 16px;
    cursor: pointer;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 12px;
    img {
      width: 28px;
      height: 28px;
    }
    .content {
      flex: 1;
      display: flex;
      justify-content: space-between;
      .name {
        max-width: 150px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        color: #122545;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .totalMember {
        font-size: 12px;
        line-height: 16px;
        color: #757d8a;
      }
    }
    &.active {
      background: #f7f9fc;
      border-right: 2px solid #076f49;
    }
    &:hover {
      background: #f7f9fc;
    }
  }
}
.templateList {
  .item {
    width: 100%;
    background: #f7faff;
    border-radius: 4px;
    margin-bottom: 20px;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
      border-bottom: 1px solid #ffffff;
      .title {
        display: flex;
        align-items: center;
        gap: 12px;
        .index {
          width: 32px;
          height: 32px;
          background: #122545;
          color: #ffffff;
          font-size: 16px;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
        }
        .text {
          max-width: 250px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 16px;
          font-weight: 600;
          color: #122545;
        }
      }
      .icons {
        display: flex;
        align-items: center;
        gap: 10px;
        /deep/ i {
          &.icon-yishoucang {
            color: #DF8F31;
          }
        }
      }
    }
    .content {
      padding: 20px;
      display: flex;
      align-items: flex-start;
      .box {
        flex: 1;
        padding-right: 10px;
        &.ql-editor {
          padding: 0;
        }
        &:nth-child(1) {
          padding-right: 10px;
          border-right: 1px solid #ffffff;
        }
        &:nth-child(2) {
          padding-left: 10px;
          color: #637381;
        }
        &.noBorder {
          border-right: 0;
          padding: 0;
        }
      }
    }
    .footer {
      padding: 10px 20px;
      border-top: 1px solid #ffffff;
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>