<template>
  <el-dialog
    :title="$tc('order.Are you sure to delete this order', this.$isOrderTitle())"
    :before-close="handleClose"
    :visible.sync="deleteOrderShow"
    :show-close="true"
    :deleteInfo="deleteInfo"
    width="360px"
    class="outDialog"
    v-move-outside
    v-if="deleteOrderShow"
  >
    <div class="deleteOrderBox">
      <i class="el-icon-warning-outline"></i>
      <div class="right">
        <div class="title">{{ $tc('order.Are you sure to delete this order', this.$isOrderTitle()) }}</div>
        <div class="content">
          <div class="item">
            {{ $t("order.Customer") }}：{{ deleteInfo && deleteInfo.customerAliasName }}
          </div>
          <div class="item">
            {{ deleteInfo && deleteInfo.ci ? $t("order.CI No") : $isOrderPITitle() }}：{{ (deleteInfo && deleteInfo.ci) || (deleteInfo && deleteInfo.pi) }}
          </div>
          <div class="item">
            {{ $t("order.Amount") }}：{{ deleteInfo && deleteInfo.amount }} {{ deleteInfo && deleteInfo.currency }}
          </div>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button size="small" class="cancel_button" @click="cancelAlldialog()">{{ $t("order.Cancel") }}</el-button>
      <el-popover
        placement="top"
        width="237"
        offset="50"
        ref="deleteComfirnPopover"
      >
        <el-input
          v-model="ciCheck"
          size="small"
          :placeholder="
            deleteInfo && deleteInfo.ci
              ? $t('order.TypeCINumber')
              : $tc('order.TypePINumber', this.$isOrderPITitle())
          "
          clearable
        ></el-input>
        <div style="text-align: right; margin-top: 18px">
          <el-button size="mini" class="cancel_button" @click="dialogClose()">{{
            $t("home.Cancel")
          }}</el-button>
          <el-button type="primary" size="mini" @click="deleteTheOrder()">{{
            $t("home.Confirm")
          }}</el-button>
        </div>
        <el-button type="primary" size="small" class="ml12" slot="reference">{{
          $t("order.delete this order")
        }}</el-button>
      </el-popover>
    </div>
  </el-dialog>
</template>

<script>
import { deleteOrder } from "@/api/order";
export default {
  data() {
    return {
      innerVisible: false,
      ciCheck: null,
      purchaseCompanyVO: [],
      vendorCompanyVO: [],
    };
  },
  props: ["deleteOrderShow", "deleteInfo"],
  inject: ["reload"],
  methods: {
    handleClose() {
      this.$emit("handleClose");
    },
    cancelAlldialog() {
      this.innerVisible = false; //为了内部的dialog复用该函数
      this.ciCheck = null;
      this.$emit("update:deleteOrderShow", false);
    },
    deleteTheOrder() {
      if(!this.ciCheck) {
        this.$message.error(
          `${
            this.deleteInfo && this.deleteInfo.ci
              ? this.$t("order.PleaseCheckTheCINumber")
              : this.$t("order.PleaseCheckThePINumber")
          }`
        );
        return;
      }
      if (this.ciCheck == this.deleteInfo.ci || this.ciCheck == this.deleteInfo.pi) {
        deleteOrder(this.deleteInfo.orderId)
          .then((response) => {
            if (!response) return this.$message.success(response.data.msg);
            this.$emit("handleClose");
            this.$emit("afterDeleteOrder");
            this.dialogClose();
            this.$message.success(this.$t('todo.Delete Successful'));
            //数据动态刷新
            this.reload(["Index", "FileDetails"]);
            // this.$store.dispatch("updateCount/orderUpdate");
          })
          .catch((error) => {});
      } else {
        this.$message.error(
          `${
            this.deleteInfo && this.deleteInfo.ci
              ? this.$t("order.PleaseCheckTheCINumber")
              : this.$t("order.PleaseCheckThePINumber")
          }`
        );
      }
    },
    dialogClose() {
      this.innerVisible = false;
      this.ciCheck = null;
      this.$refs.deleteComfirnPopover.showPopper = false;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header {
  display: none;
}
.dialog_body {
  position: relative;

  .close {
    position: absolute;
    top: -57px;
    right: 0;

    i {
      font-size: 22px;
      cursor: pointer;
    }
  }
}
.order_info {
  width: 100%;
  // height: 69px;
  background: #fcfcfc;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 20px;
  /deep/ .el-divider--vertical {
    margin: 0 40px 0 8px;
  }
  .order_info_content {
    flex: 1;
    &.teshu {
      flex: 1.4;
    }
    p {
      font-size: 14px;
      color: #333333;
    }
    .customers {
      margin-bottom: 4px;
      font-size: 12px;
      color: #b3b3b3;
    }
  }
}
</style>
