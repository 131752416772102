<template>
  <el-dialog
    :title="$t('Filter custom information')"
    :visible.sync="show"
    width="360px"
    append-to-body
    :before-close="close"
    v-move-outside
  >
    <div class="checkBox">
      <el-input 
        size="small" 
        v-model="params.customName"
        style="margin-bottom: 30px;"
        :placeholder="$t('order.Please input content')"
        @input="getList(true)"
      ></el-input>
      <div class="list" v-if="dataList.length">
        <el-checkbox v-for="(item, index) in dataList" :key="index" v-model="item.check">
          <span class="checkSpan line1">{{ item.customName }}</span>({{item.orderCount}})
        </el-checkbox>
      </div>
      <el-empty 
        v-else 
        :description="$t('overallSituation.noData')" 
        :image="$emptyImage"
        :image-size="90"
      />
      <div class="pagination">
        <el-pagination
          @size-change="changePageSizeHandler"
          @current-change="changePageHandler"
          :current-page="params.page"
          :page-sizes="[20, 30, 40, 50, 100]"
          :total="total"
          small
          layout="prev, pager, next"
        />
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button 
        class="cancel_button" 
        size="small" 
        @click="close">
        {{ $t('home.Cancel') }}
      </el-button>
      <el-button type="primary" size="small" @click="confirm">
        {{ $t('home.Confirm') }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getCustomTitlePage } from "@/api/order.js";
export default {
  data() {
    return {
      show: false,
      params: {
        customName: "",
        page: 1,
        size: 10
      },
      total: 0,
      dataList: [],
      formArray: []
    }
  },
  methods: {
    open(formArray) {
      this.show = true;
      this.formArray = formArray ? formArray.split(',') : [];
      this.getList();
    },
    close() {
      this.show = false;
    },
    getList() {
      getCustomTitlePage(this.params).then((data) => {
        this.dataList = data.list.map(item => ({
          ...item,
          check: this.formArray.includes(item.customName)
        }));
        this.total = data.total;
      })
    },
    changePageHandler(page) {
      this.params.page = page;
      this.getList();
    },
    changePageSizeHandler(pageSize) {
      this.params.size = pageSize;
      this.getList();
    },
    confirm() {
      this.$emit("generateCustom", this.dataList.filter(item => item.check).map(item => item.customName));
      this.close();
    }
  }
}
</script>

<style lang="less" scoped>
.checkBox {
  .list {
    display: flex;
    flex-wrap: wrap;
    /deep/ .el-checkbox {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      .el-checkbox__label {
        display: flex;
        align-items: center;
        .checkSpan {
          display: inline-block;
          max-width: 120px;
        }
      }
    }
  }
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
}
</style>