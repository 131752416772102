<template>
  <el-steps
    active="1"
    :direction="vertical ? 'vertical' : ''"
    align-center
    space="90px"
    class="order-progress"
  >
    <el-step
      icon="el-icon-edit"
      v-for="(item, index) in stepState"
      :key="index"
      :title="vertical ? item : null"
    >
      <template #icon>
        <el-popover placement="top" :title="item.orderStatus" trigger="hover">
          <img
            :src="iconPath(index)"
            class="order-state-icon"
            alt="icon"
            slot="reference"
          />
          <div>{{ item.updateTime }}</div>
        </el-popover>
      </template>
    </el-step>
  </el-steps>
</template>
<script>
import { computed, ref } from '@vue/composition-api';

export default {
  name: 'steps',
  props: {
    stepInfo: {
      type: Object,
      default: function () {
        return {
          step: 0,
          updateTime: '',
        };
      },
    },
    vertical: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const statusEnum = ref([
      'PRODUCTION',
      'DELIVERY_LOCAL',
      'SEND_PORT',
      'DELIVERY_TRANS',
      'RECEIVE_PORT',
      'DELIVERY_DEST',
      'DESTINATION',
    ]);

    const stepState = computed(() => {
      return props.stepInfo;
    });

    function iconPath(index) {
      if (stepState.value.step === index) {
        return `https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/order/${index}-2.png`;
      } else if (stepState.value.step > index) {
        return `https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/order/${index}-3.png`;
      } else {
        return `https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/order/${index}-1.png`;
      }
    }

    return {
      statusEnum,
      stepState,
      iconPath,
    };
  },
  vertical: {
    type: Boolean,
    default: false,
  },
  setup(props) {
    const statusEnum = ref([
      'PRODUCTION',
      'DELIVERY_LOCAL',
      'SEND_PORT',
      'DELIVERY_TRANS',
      'RECEIVE_PORT',
      'DELIVERY_DEST',
      'DESTINATION',
    ]);

    const stepState = computed(() => {
      return props.stepInfo;
    });

    function iconPath(index) {
      if (stepState.value.step === index) {
        return `https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/order/${index}-2.png`;
      } else if (stepState.value.step > index) {
        return `https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/order/${index}-3.png`;
      } else {
        return `https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/order/${index}-1.png`;
      }
    }

    return {
      statusEnum,
      stepState,
      iconPath,
    };
  },
};
</script>
<style lang="css">
.el-step__icon.is-icon {
  width: 20px;
}

.order-progress.el-steps {
  align-items: center;
  /* justify-content: center; */
  min-height: 40px;
}

.order-progress.el-steps .el-step__head {
  display: grid;
  align-items: center;
}

.order-progress.el-steps .el-step__line {
  /* left: auto;
  right: 0;
  width: 50%; */
  left: auto;
  right: 8px;
  width: 53%;
  top: 9px;
}

.order-progress .el-step__icon {
  background-color: transparent;
}

.order-progress .el-step__line {
  background-color: transparent;
  border-top: 1px dashed #d0d0d0;
  margin-block-start: 1px;
}

.order-progress .order-state-icon {
  width: 32px;
  height: 32px;
  z-index: 1;
}

.order-progress.el-steps--vertical .el-step__line {
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  top: 56px !important;
  bottom: -8px !important;
  height: 70%;
}

.el-step.is-vertical .el-step__line {
  width: 1px !important;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-style: dashed;
  border-top: none;
  border-bottom: none;
  border-left: none;
}

.order-progress.el-steps--vertical {
  align-items: flex-start;
}

.order-progress.el-steps--vertical .el-step__main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  margin-inline-start: 20px;
  padding-inline-start: 0;
}

.order-progress.el-steps--vertical .el-step__title {
  padding-block-end: 0;
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.65);
}

.el-table::before {
  background-color: transparent;
}
</style>
