<template>
  <el-card class="routerBox">
    <pageTable 
      ref="multipleTable"
      :data="orderList"
      :row-key="getRowKeys"
      @sort-change="sortChange"
      v-loading="loading_table"
      :searchParams.sync="form"
      @searchEvent="getOrderList(true)"
      @clearEvent="clearFilter"
      @updateTable="getOrderList"
      @selection-change="handleSelectionChange">
      <template #searchForm>
        <el-select
          v-model="form.importCountry"
          filterable
          size="small"
          :placeholder="$t('order.Please select country')"
          style="width: 200px;"
          clearable
          @change="getOrderList(true)"
        >
          <el-option
            v-for="(item, index) in country"
            :key="index"
            :label="item.key"
            :value="item.value"
          />
        </el-select>
        <el-select
          v-model="form.companyId"
          filterable
          size="small"
          :placeholder="$t('order.Please select')"
          style="width: 200px;"
          @change="getOrderList(true)"
          clearable
        >
          <el-option
            v-for="(item, index) in customerList"
            :key="index"
            :label="item.aliasName"
            :value="item.companyId"
          >
            <div class="option_list">
              <div>{{ item.aliasName || item.name }}</div>
              <div>{{ $t(`home.${$companyTypes[item.companyType]}`) }}</div>
            </div>
          </el-option>
        </el-select>
        <el-input
          v-model="form.pi"
          size="small"
          :placeholder="$isOrderPITitle()"
          style="width: 200px;"
          class="filter-item"
          auto-complete="off"
          clearable
          @keyup.enter.native="getOrderList(true)"
        >
          <i
            style="padding-top: 1px"
            class="el-icon-search el-input__icon"
            slot="prefix"
          >
          </i>
        </el-input>
        <el-input
          v-model="form.ci"
          v-if="$store.state.user.orderAlias == 0"
          size="small"
          :placeholder="$t('order.Input CI')"
          style="width: 200px;"
          class="filter-item"
          auto-complete="off"
          clearable
          @keyup.enter.native="getOrderList(true)"
        >
          <i
            style="padding-top: 1px"
            class="el-icon-search el-input__icon"
            slot="prefix"
          ></i>
        </el-input>
        <el-autocomplete
          v-model="form.uploadPerson"
          size="small"
          :placeholder="$t('InformationCenter.Createby')"
          style="width: 200px;"
          class="filter-item"
          clearable
          :fetch-suggestions="getListCompanyUserArraySelect"
          @select="uploadPersonSelect"
          suffix-icon="el-icon-search"
        >
          <template slot-scope="{ item }">
            <div style="display: flex; align-items: center; column-gap: 12px">
              <el-avatar size="small" :src="item.avatarFileUrl"></el-avatar>
              <span
                style="
                  width: 145px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
                >{{ item.nickName }}</span
              >
            </div>
          </template>
        </el-autocomplete>
        <el-date-picker
          v-model="dateRange"
          size="small"
          type="daterange"
          :start-placeholder="$t('order.Start from—End to').split('—')[0]"
          :end-placeholder="$t('order.Start from—End to').split('—')[1]"
          style="width: 250px;"
        >
        </el-date-picker>
        <el-button size="small" @click="openCustomDialog">
          {{ $t('Filter custom information') }}
        </el-button>
      </template>
      <template #searchAction>
        <el-button
          v-if="companyCurrentType == '1'"
          v-permission="'create:order:btn'"
          type="primary"
          @click="createOrder()"
          size="small"
          icon="el-icon-plus"
          >{{ $tc("order.New Order", $isOrderTitle()) }}</el-button
        >
        <el-dropdown 
          size="small" 
          split-button
          class="batchOrderBtn"
          trigger="click"
          style="margin-left: 12px;"
          @click="isMultiple = !isMultiple">
          {{ !isMultiple ? $t('order.Multiple') : $t('order.Cancel multiple') }}
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-permission="'batch:order:btn'" :class="!multipleSelection.length ? 'noClick' : ''" icon="iconfont icon-tianjiarenyuan" @click.native="batchAddOrderMember">{{ $t("people.NewMessage") }}</el-dropdown-item>
            <el-dropdown-item v-permission="'batch:order:btn'" :class="!multipleSelection.length ? 'noClick' : ''" icon="iconfont icon-yichurenyuan" @click.native="batchDeleteOrderMember">{{ $t("deleteEmployee.Tips") }}</el-dropdown-item>
            <el-dropdown-item v-permission="'batch:order:delete:btn'" :class="!multipleSelection.length ? 'noClick' : ''" icon="iconfont icon-shanchu" @click.native="deleteBatchOrder">{{ $t("order.Delete order") }}</el-dropdown-item>
            <el-dropdown-item icon="iconfont icon-xiazai" :class="!multipleSelection.length ? 'noClick' : ''" @click.native="handleDownload">{{ $t("order.export") }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
      <template #tableColumn>
        <el-table-column
          v-if="isMultiple"
          :reserve-selection="true"
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column label="No." type="index" width="50" />
        <el-table-column
          ref="popover_order"
          type="index"
          label="Follow"
          width="70"
          :filters="[
            { text: $t('home.Follow'), value: '1' },
            { text: $t('home.Nofollow'), value: '0' },
          ]"
          :filter-multiple="false"
          :filtered-value="filteredFollow"
        >
          <!-- :filter-method="filterFollow" -->
          <template slot="header">
            <i
              class="iconfont icon-yishoucang"
              style="font-size: 16px; vertical-align: middle;color: #DF8F31;"
            ></i>
          </template>
          <template slot-scope="scope">
            <div class="star">
              <i
                class="iconfont"
                :class="
                  scope.row.favorite ? 'icon-yishoucang' : 'icon-shoucang'
                "
                @click.stop="
                  !scope.row.favorite ? followOrder(scope.row, scope.$index) : unFollowOrder(scope.row, scope.$index)
                "
              ></i>
            </div>
          </template>
        </el-table-column>
        <el-table-column 
          v-for="(item, index) in form.customNames ? form.customNames.split(',') : []"
          :key="index"
          :label="item" 
          min-width="150">
          <template slot-scope="{row}">
            <div v-if="Array.isArray(row.customList)">
              <div v-if="customListComputed(row, item)">
                <template v-if="customListComputed(row, item).customType == 1">
                  {{ JSON.parse(customListComputed(row, item).customOptions).find(val => val.value == customListComputed(row, item).customValue).label }}
                </template>
                <template v-else-if="customListComputed(row, item).customType == 2">
                  {{ (customListComputed(row, item).customValue ? customListComputed(row, item).customValue.split(",") : []).map(xm => JSON.parse(customListComputed(row, item).customOptions).find(val => val.value == xm) && JSON.parse(customListComputed(row, item).customOptions).find(val => val.value == xm).label).join(",") }}
                </template>
                <template v-else-if="customListComputed(row, item).customType == 3 || customListComputed(row, item).customType == 4">
                  {{ customListComputed(row, item).customValue.split(",").filter(item => item).length < 2 ? customListComputed(row, item).customValue.split(",")[0] : customListComputed(row, item).customValue.split(",").join(" - ") }}
                </template>
                <template v-else-if="customListComputed(row, item).customType == 9">
                  {{ JSON.parse(customListComputed(row, item).customValue) ? JSON.parse(customListComputed(row, item).customOptions).open || $t('Open') : JSON.parse(customListComputed(row, item).customOptions).close || $t('Close') }}
                </template>
                <template v-else>
                  {{ customListComputed(row, item).customValue }}
                </template>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="companyCurrentType == '1' ? $t('order.Purchaser') : (companyCurrentType == '0' ? $t('home.VENDOR') : companyTypeList)"
          min-width="130"
          prop="customerAliasName"
        />
        <el-table-column
          prop="receiptCountry"
          :label="$t('order.Please select country')"
          column-key="receiptCountry"
          min-width="100"
        >
          <template slot-scope="scope">
            {{ $isFindData(country, scope.row.country, 'value', 'key') }}
          </template>
        </el-table-column>
        <el-table-column sortable prop="pi" :label="$isOrderPITitle()" min-width="150">
          <template slot-scope="scope">
            <div
              @click="!scope.row.ci ? linkToOrder(scope.row) : ''"
              :class="!scope.row.ci ? 'aLink' : ''"
            >
              {{ scope.row.pi }}
            </div>
          </template>
        </el-table-column>
        <el-table-column v-if="$store.state.user.orderAlias == 0" sortable prop="ci" label="CI" min-width="130">
          <template slot-scope="scope">
            <div
              @click="linkToOrder(scope.row)"
              class="aLink"
            >{{ scope.row.ci }}</div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('InformationCenter.Createby')" min-width="160">
          <template slot-scope="scope">
            <div class="customerName">
              <member :info="scope.row.openUserDTO" :size="28"></member>
              <span>{{ scope.row.openUserDTO && scope.row.openUserDTO.nickName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          sortable
          :label="$t('order.Create Time')"
          column-key="createTime"
          min-width="190"
        >
        </el-table-column>
        <el-table-column
          prop="currency"
          column-key="currency"
          :filters="currencys.map(item => ({
            text: item.key,
            value: item.value
          }))"
          :filter-multiple="false"
          :filtered-value="filterCurrency"
          :label="$t('home.Currency')"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          prop="amount"
          sortable
          :label="$t('order.Amount')"
          min-width="100"
          column-key="amount"
        >
          <template slot-scope="scope">
            <a>{{ count(Number(scope.row.amount)) }}</a>
          </template>
        </el-table-column>
        <el-table-column
          v-if="isDefaultNode"
          :filters="orderProgress.map(item => ({
            text: item.key,
            value: item.value
          }))"
          :filter-multiple="false"
          :filtered-value="filterStatus"
          :label="$t('order.Status')"
          min-width="250"
          class-name="orderStateColumn"
        >
          <template slot-scope="scope">
            <div class="orderStateProgress">
              <el-tooltip
                effect="light"
                placement="top"
              >
                <div class="progressContent" slot="content">
                  <div 
                    class="item" 
                    v-for="(item, index) in scope.row.progressList.filter((item, index) => index <= scope.row.progressList.findIndex(row => row.progress == scope.row.progress))" 
                    :key="index">
                    <div class="label">
                      <i class="el-icon-check"></i>
                      {{ computedNodeList(item.progress).name }}
                    </div>
                    <div class="time">{{ progressTime(item, scope.row) }}</div>
                  </div>
                </div>
                <div
                  class="stateIconBox" 
                  :style="{
                    left: `calc(${scope.row.onProgress}% - 10px)`,
                    background: orderStatusColors[scope.row.progressList.findIndex(item => item.progress == scope.row.progress)]
                  }">
                  <i :class="`state_icon iconfont ${scope.row.icon}`"></i>
                </div>
              </el-tooltip>
              <el-progress :percentage="scope.row.onProgress" :color="orderStatusColors[scope.row.progressList.findIndex(item => item.progress == scope.row.progress)]" :stroke-width="8" :show-text="false"></el-progress>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-else
          :label="$t('order.Status')"
          min-width="250"
          class-name="orderStateColumn"
        >
          <template slot-scope="scope">
            <div class="orderStateProgress">
              <el-tooltip
                effect="light"
                placement="top"
              >
                <div class="progressContent" slot="content">
                  <div 
                    class="item" 
                    v-for="(item, index) in scope.row.progressList.filter((item, index) => index <= scope.row.progressList.findIndex(row => row.progress == scope.row.progress))" 
                    :key="index">
                    <div class="label">
                      <i class="el-icon-check"></i>
                      {{ computedNodeList(item.progress).name }}
                    </div>
                    <div class="time">{{ progressTime(item, scope.row) }}</div>
                  </div>
                </div>
                <div
                  class="stateIconBox" 
                  :style="{
                    left: `calc(${scope.row.onProgress}% - 10px)`,
                    background: orderStatusColors[scope.row.progressList.findIndex(item => item.progress == scope.row.progress)]
                  }">
                  <i :class="`state_icon iconfont ${scope.row.icon}`"></i>
                </div>
              </el-tooltip>
              <el-progress :percentage="scope.row.onProgress" :color="orderStatusColors[scope.row.progressList.findIndex(item => item.progress == scope.row.progress)]" :stroke-width="8" :show-text="false"></el-progress>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('customerInfo.Action')"
          min-width="90"
          fixed="right"
        >
          <template slot-scope="scope">
            <div class="action">
              <div
                class="btn" 
                v-if="scope.row.createPerson == businessUserId && (scope.row.progressList[scope.row.progressList.length - 1].progress != scope.row.progress)"
                @click="editOrder(scope.row)">
                <i class="el-icon-edit"></i>
              </div>
              <el-dropdown
                trigger="click" 
                placement="bottom-start"
                @visible-change="visibleDropdownChange">
                <div
                  class="btn" 
                  :class="tableSelectedShow === scope.$index ? 'active' : ''"
                  @click="tableSelectedShow = scope.$index">
                  <i class="iconfont icon-Ellipsis"></i>
                </div>
                <el-dropdown-menu class="ec-popper" slot="dropdown">
                  <el-dropdown-item
                    class="popperMenuItem"
                    icon="iconfont icon-xianshi"
                    @click.native="orderId = scope.row.orderId"
                  >{{ $t('order.skuPreview') }}</el-dropdown-item>
                  <el-dropdown-item
                    class="popperMenuItem"
                    icon="iconfont icon-shanchu"
                    v-if="scope.row.createPerson == businessUserId"
                    @click.native="deleteOrder(scope.row)"
                  >{{ $t('home.Delete') }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </template>
    </pageTable>
    <createOrder
      ref="createOrderDialog"
      :editContent="editContent"
      :customerInfo="null"
      :action="action"
      @afterCreateOrder="afterCreateOrder"
    />
    <deleteOrder
      :deleteOrderShow.sync="deleteOrderShow"
      @handleClose="deleteOrderShow = false"
      :deleteInfo="deleteInfo"
      @afterDeleteOrder="afterDeleteOrder"
    />
    <skuView :orderId="orderId" @close="orderId = ''"></skuView>
    <el-dialog
      :title="this.$t('order.Enter login password to confirm')"
      :visible.sync="isDeletePassword"
      width="358px"
      :before-close="handleDleteOrderClose"
      v-move-outside>
      <div class="deletePasswordStyle">
        <el-input 
          v-model="deletePassword" 
          :type="!isShowPassword ? 'password':'text'" 
          :placeholder="$t('login.Password')"
          size="small">
          <i
            :class="`iconfont ${!isShowPassword ? 'icon-xianshi' : 'icon-yincang'} el-input__icon`"
            slot="suffix"
            @click="isShowPassword = !isShowPassword">
          </i>
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="handleDleteOrderClose">取 消</el-button>
        <el-button type="primary" size="small" @click="deletePasswordSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-drawer
      :title="batchMemberMode == 'add' ? $t('people.NewMessage') : $t('deleteEmployee.Tips')"
      :append-to-body="true"
      :visible.sync="isBatchMemberVisible"
      size="400px"
      :before-close="batchMemberClose"
      v-move-outside
    >
      <div class="inside_box">
        <div class="inside_box_search">
          <el-input
            v-model="batchEditMemberForm.userName"
            :placeholder="$t('Files.Input Name')"
            clearable
            size="small"
          />
        </div>
        <div class="inviteBox" v-loading="batchMemberLoading">
          <template v-if="(batchMemberData.filter(item => item.nickName.toLowerCase().indexOf(batchEditMemberForm.userName.toLowerCase()) != -1)).length">
            <div 
              class="item" 
              v-for="(item, idx) in batchMemberData.filter(item => item.nickName.toLowerCase().indexOf(batchEditMemberForm.userName.toLowerCase()) != -1)" 
              :key="idx"
              @click.prevent="checkedBatchChange(item)">
              <member :info="item" :size="48" />
              <div class="content">
                <div class="name">{{ item.nickName }}</div>
                <div class="job">{{ item.position }}</div>
              </div>
              <el-checkbox v-model="item.check" />
            </div>
          </template>
          <el-empty
            :description="$t('overallSituation.noData')"
            :image="$emptyImage"
            :image-size="90"
            v-else
          />
        </div>
        <div class="selectSwiperBox">
          <el-carousel 
            height="153px" 
            :autoplay="false" 
            arrow="always"
            indicator-position="outside">
            <el-carousel-item 
              v-for="(item, index) in carouselMemberData" 
              :key="index">
              <div class="userItem" v-for="(row, i) in item" :key="i">
                <member :info="row" :size="50" />
                <div class="name line1">{{ row.nickName }}</div>
                <i class="el-icon-error" @click="closeSelectOrderMember(row, i)"></i>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="drawerFooter flexAlignBetween">
        <div class="selectTotal">
          {{ $tc('home.selectedPerson', batchMemberData.filter(item => item.check).length) }}
        </div>
        <div>
          <el-button
            class="cancel_button" 
            size="small"
            @click="batchMemberClose"
          >
            {{ $t('Cancel') }}
          </el-button>
          <el-button
            type="primary"
            size="small"
            @click="batchMemberSubmit"
            :loading="batchMemberBtnLoading"
          >
            {{ $t('home.Confirm') }}
          </el-button>
        </div>
      </div>
    </el-drawer>
    <customTitleConfigDialog ref="customTitleConfigDialog" @generateCustom="generateCustom" />
  </el-card>
</template>

<script>
import dayjs from "dayjs";
import {
  getOrderList,
  follow,
  unfollow,
  deleteBatchOrder,
  addBatchOrderMember,
  deleteBatchOrderMember,
  batchActionOrderMember
} from "@/api/order";
import { getPublicKey, confirmPassword } from "@/api/index";
import createOrder from "@/components/createOrder/createOrder";
import Steps from "@/components/orderProgressOrder/orderProgressOrder";
import deleteOrder from "@/components/deleteOrder/deleteOrder";
import customTitleConfigDialog from "./components/customTitleConfigDialog.vue";
import { mapGetters } from "vuex";
import { 
  getUserCustomList, 
  getCompanyEmployeeList
} from "@/api/customer";
import {
  getCompanyEmployeeListAndRelation,
} from '@/api/comment';
import { formatNum } from "../../utils/utils";
import { rsaData } from "@/utils/encrypt";
import Config from "@/store/Config";
import Template from '../../components/emailAssiste/components/template.vue';

export default {
  name: "Order",
  components: { 
    Steps, 
    createOrder, 
    deleteOrder,
    customTitleConfigDialog
  },
  inject:
    Template ["reload"],
  data() {
    return {
      dayjs: dayjs,
      tableSelectedShow: null,
      searchVal: "",
      batchEditMemberForm: {
        userName: ""
      },
      batchMemberData: [],
      customerList: [],
      batchMemberBtnLoading: false,
      deleteOrderShow: false,
      isMultiple: false,
      loading_table: false,
      CI_show: false,
      Company_show: true,
      orderList: [],
      sortTypeFollow: "",
      deleteInfo: null,
      multipleSelection: [],
      filteredFollow: [],
      filterCurrency: [],
      filterStatus: [],
      filterCompany: [],
      dateRange: [],
      batchMemberMode: "",
      isShowPassword: false,
      deletePassword: "",
      isDeletePassword: false,
      form: {
        importCountry: "",
        companyId: "",
        ci: "",
        pi: "",
        searchValue: "",
        type: "",
        title: "",
        sortType: "",
        currency: "",
        uploadPerson: "",
        orderProgress: "",
        follow: "",
        sortField: "",
        customNames: "",
        currentPage: 1,
        pageSize: 10,
        totalElement: 0
      },
      isBatchMemberVisible: false,
      batchMemberLoading: false,
      companyTypeList: this.$t("order.Supplier"),
      statusEnum: [
        "PRODUCING",
        "DELIVERY_LOCAL",
        "SEND_PORT",
        "DELIVERY_TRANS",
        "RECEIVE_PORT",
        "DELIVERY_DEST",
        "DESTINATION",
      ],
      action: "create",
      editContent: "",
      importanceOptions: ["Company", "CI"],
      inputStatus: true,
      inputPlace: "Input Company or CI",
      orderCurrencyLists: [],
      orderId: "",
    };
  },
  computed: {
    ...mapGetters([
      "companyCurrentType",  
      "country", 
      "orderStatusColors", 
      "businessUserId",
      "orderProgress",
      "currencys",
      "newOrderUpdate",
      "companyId",
      "allNodeList",
      "isDefaultNode"
    ]),
    computedNodeList() {
      return function (type) {
        return this.allNodeList.find(item => item.type == type);
      }
    },
    customListComputed() {
      return function (row, item) {
        return row.customList.find(value => value.customName == item);
      }
    },
    progressTime() {
      return function (row, item) {
        return item.progressList.find(dd => dd.progress == row.progress).date
      }
    },
    carouselMemberData() {
      let list = this.batchMemberData.filter(item => item.check);
      let len = list.length
      let newList = []
      if (len) {
        var chunk = 4
        for (var i = 0, j = len; i < j; i += chunk) {
          newList.push(list.slice(i, i + chunk))
        }
      }
      return newList
    }
  },
  watch: {
    filteredFollow() {
      this.getOrderList(true);
    },
    filterCurrency(newData, oldData) {
      this.form.currency = newData[0];
      this.getOrderList(true);
    },
    isMultiple() {
      this.multipleSelection = [];
      this.$refs.multipleTable.$children.forEach(item => {
        if(item.tableId) {
          item.clearSelection();
        }
      });
    },
    filterStatus(newData, oldData) {
      this.form.orderProgress = newData[0];
      this.getOrderList(true);
    },
    filterCompany(newData, oldData) {
      this.companyTypeList = newData[0];
      this.getOrderList(true);
    },
    newOrderUpdate() {
      this.getOrderList();
    }
  },
  activated() {
    if (this?.$route?.query?.PI) {
      this.form.pi = this.$route.query.PI;
      this.getOrderList();
    }
  },
  mounted() {
    this.form.companyType = this.companyCurrentType;
    this?.$route?.query?.PI ? "" : this.getList();
    this.form.companyId = this.$route.query.companyId || "";
  },
  methods: {
    generateCustom(data) {
      this.form.customNames = data.join(",");
      this.getOrderList(true);
    },
    openCustomDialog() {
      this.$refs.customTitleConfigDialog.open(this.form.customNames);
    },
    checkedBatchChange(row) {
      row.check = !row.check;
    },
    closeSelectOrderMember(row, i) {
      this.batchMemberData.forEach(item => {
        if(item.openId == row.openId) {
          item.check = false;
        }
      })
    },
    getListCompanyUserArraySelect(name, cb) {
      if(!name) {
        cb([]);
        return;
      }
      getCompanyEmployeeListAndRelation({
        userName: name,
        includeSelf: true,
        currentPage: 1,
        pageSize: 10000
      }).then((data) => {
        cb(data.list.map(item => item.user));
      }).catch(() => {
        cb([]);
      })
    },
    uploadPersonSelect(item) {
      this.form.uploadPerson = item.nickName;
    },
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val;
    },
    getRowKeys(row) {
      return row.orderId;
    },
    deletePasswordSubmit() {
      if(!this.deletePassword) {
        this.$message.warning(this.$t('login.Pleaseinputapassword'))
        return;
      }
      getPublicKey().then((response) => {
        if (!response) return;
        confirmPassword(false == Config.IS_DEBUG ? rsaData(
          this.deletePassword,
          response
        ) : this.deletePassword).then((data) => {
          deleteBatchOrder(this.multipleSelection.map(item => item.orderId)).then(() => {
            this.getOrderList(true);
            this.$message.success(this.$t('todo.Delete Successful'));
            this.reload(["Index", "FileDetails"]);
            this.handleDleteOrderClose(false);
          }).catch((error) => {
            this.$message.error("unfollow order error");
          });
        });
      }).catch((error) => {});
    },
    handleDleteOrderClose(isUpdate = true) {
      this.isDeletePassword = false;
      this.deletePassword = "";
      if(isUpdate) {
        this.deleteBatchOrder();
      }
    },
    deleteBatchOrder() {
      if(!this.multipleSelection.length) {
        this.$message.warning(this.$t('Please select the data to operate on'));
        return;
      }
      this.$confirm(
        this.$t("order.Unrecoverable after deletion"),
        this.$tc('order.batchDeleteOrderTip', this.multipleSelection.length),
        {
          confirmButtonText: this.$t("order.Confirms"),
          cancelButtonText: this.$t("order.Cancel"),
          type: "warning",
        }
      ).then(() => {
        this.isDeletePassword = true;
      }).catch(() => {});
    },
    batchMemberClose() {
      this.batchMemberMode = "";
      this.isBatchMemberVisible = false;
      this.batchMemberData = [];
    },
    batchMemberSubmit() {
      if(!this.batchMemberData.filter(item => item.check).length) {
        this.$message.warning(this.$t('Please select the data to operate on'));
        return;
      }
      const type = this.batchMemberMode == 'add';
      this.$confirm(
        type ? this.$t('order.After adding, you can view order related information') : this.$t('order.After removal, you will no longer be able to view orders'),
        type ? this.$t('order.Are you sure to add personnel') : this.$t('order.Are you sure to remove the personnel'),
        {
          confirmButtonText: this.$t("order.Confirms"),
          cancelButtonText: this.$t("order.Cancel"),
          type: "warning",
        }
      ).then(() => {
        batchActionOrderMember({
          orderIds: this.multipleSelection.map(item => item.orderId),
          openIds: this.batchMemberData.filter(item => item.check).map(item => item.openId),
          type: type ? 1 : 2, 
          check: 1
        }).then((data) => {
          if(data.number) {
            this.$msgbox({
              title: this.$tc('number people cannot be added', {
                number: data.number,
                type
              }),
              customClass: "batchOrderValiteDialog",
              message: (
                <div class="batchValiteBox">
                  {
                    data.list.map((item, index) => (
                      <div class="item" key={index}>
                        <div class="left">
                          <img src={item.avatar} />
                          <div class="name">{item.nickName}</div>
                        </div>
                        <div class="right">{item.content}</div>
                      </div>
                    ))
                  }
                </div>
              ),
              showCancelButton: true,
              confirmButtonText: this.$t("order.Confirms"),
              cancelButtonText: this.$t("order.Cancel"),
            }).then(() => {
              this.submitBatchApi();
            }).catch(() => {});
          } else {
            this.submitBatchApi();
          }
        })
      }).catch(() => {});
    },
    submitBatchApi() {
      const type = this.batchMemberMode == 'add';
      batchActionOrderMember({
        orderIds: this.multipleSelection.map(item => item.orderId),
        openIds: this.batchMemberData.filter(item => item.check).map(item => item.openId),
        type: type ? 1 : 2, 
        check: 2
      }).then(() => {
        this.$message.success(type ? this.$t('home.Invitethesuccessful') : this.$t('home.Deletionsucceeded'));
        this.getOrderList();
        this.reload(["OrderDetails"]);
        this.batchMemberClose();
        this.isMultiple = false;
      })
    },
    batchAddOrderMember() {
      if(!this.multipleSelection.length) {
        this.$message.warning(this.$t('Please select the data to operate on'));
        return;
      }
      this.batchMemberMode = "add";
      this.getBatchMemberData();
      this.isBatchMemberVisible = true;
    },
    batchDeleteOrderMember() {
      if(!this.multipleSelection.length) {
        this.$message.warning(this.$t('Please select the data to operate on'));
        return;
      }
      this.batchMemberMode = "delete";
      this.getBatchMemberData();
      this.isBatchMemberVisible = true;
    },
    getBatchMemberData() {
      this.batchMemberLoading = true;
      getCompanyEmployeeList(this.companyId).then((response) => {
        if (!response) return;
        this.batchMemberData = response.memberList.map(item => ({
          ...item, 
          check: false
        }));
      }).finally(() => (this.batchMemberLoading = false));
    },
    //删除订单更新前端界面
    afterDeleteOrder() {
      this.form.currentPage = 1;
      this.form.pageSize = 10;
      //删除之后再初始化一次桌面订单清单
      this.reload(["Customer", "Index"]);
      this.getOrderList();
    },
    visibleDropdownChange(val) {
      if(!val) {
        this.tableSelectedShow = null;
      }
    },
    count(c) {
      return formatNum(c.toFixed(3) || 0.0);
    },
    //获取客户中Forwarder的表
    getList() {
      this.customerList = [];
      this.getOrderList();
      getUserCustomList({
        currentPage: 1,
        pageSize: 100
      }).then((response) => {
        if (!response) return;
        this.customerList = response.list;
      }).catch((error) => {});
    },
    getOrderList(isPage) {
      if(isPage) {
        this.form.currentPage = 1;
      }
      let form = {
        ...this.form,
        startTime: this.dateRange && this.dateRange.length ? dayjs(this.dateRange[0]).format("YYYY-MM-DD HH:mm:ss") : '',
        endTime: this.dateRange && this.dateRange.length ? dayjs(this.dateRange[1]).endOf("day").format("YYYY-MM-DD HH:mm:ss") : '',
        favorite: this.filteredFollow[0]
      }
      this.loading_table = true;
      getOrderList(form).then((response) => {
        if (!response) return;
        this.orderList = [];
        this.form.totalElement = response.total;
        this.orderList = response.list.map(item => ({
          ...item,
          progress: Number(item.progress),
          icon: this.allNodeList.find(row => row.type == item.progress).icon,
          onProgress: Math.floor(1/(item.progressList.length)*(item.progressList.findIndex(row => row.progress == item.progress)+1)*100)
        }));
        this.$nextTick(() => {
          this.$refs.multipleTable.$children.forEach(item => {
            if(item.tableId) {
              item.doLayout();
            }
          });
        });
      }).catch((error) => {})
      .finally(() => (this.loading_table = false));
    },
    selectTrigger(val) {
      if (val != "") {
        this.inputStatus = false;
        if (val == "CI") {
          this.CI_show = true;
          this.Company_show = false;
          this.inputPlace = "Input CI";
        } else if (val == "Company") {
          this.CI_show = false;
          this.Company_show = true;
          this.inputPlace = "Input Company";
        }
      } else if (val == "") {
        this.inputStatus = true;
        this.CI_show = true;
        this.Company_show = false;
        this.inputPlace = "Input Company or CI";
      }
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          (restaurant.name += "")
            .toLowerCase()
            .indexOf((queryString += "").toLowerCase()) === 0
        );
      };
    },
    handleSelect(item) {
      this.form.title = item.name;
      this.form.companyId = item.companyId;
    },
    handerRowClick(row) {
      this.$router.push({
        path: "/order/orderDetails",
      });
    },
    // filterHandler方法
    filterHandler(value, row, column) {
      const property = column["property"];
      return row[property] === value;
    },
    // filterStatus(value, row, column) {
    //
    //   const property = column['property'];
    //   return row[property] === value;
    // },
    filterFollow(value, row, column) {
      const property = column["property"];
      this.sortTypeFollow = value;

      return row[property] === value;
    },
    clearFilter() {
      //重新获取
      this.form = {
        importCountry: "",
        companyId: "",
        CI: "",
        PI: "",
        searchValue: "",
        type: "",
        title: "",
        dateRange: [],
        sortType: "",
        currency: "",
        orderProgress: "",
        follow: "",
        sortField: "",
        customNames: "",
        currentPage: 1,
        pageSize: 10,
        totalElement: 0
      };
      this.getOrderList(true);
    },
    //创建新订单
    createOrder() {
      this.action = "create";
      this.editContent = null;
      this.$refs.createOrderDialog.open();
    },
    //收藏订单
    followOrder(item, index) {
      this.$refs.popover_order.showPopper = false;
      var data_follow = {
        orderId: item.orderId,
      };
      follow(data_follow)
        .then(() => {
          this.reload(["Index"]);
          this.getOrderList();
        });
    },
    //解除收藏订单
    unFollowOrder(item, index) {
      this.$refs.popover_order.showPopper = false;
      this.$confirm(
        this.$t("order.Confirm unfollow this order"),
        this.$t("order.Unfollow"),
        {
          confirmButtonText: this.$t("order.Confirms"),
          cancelButtonText: this.$t("order.Cancel"),
          type: "warning",
        }
      )
        .then(() => {
          var data_unfollow = {
            orderId: item.orderId,
          };
          unfollow(data_unfollow)
            .then((response) => {
              this.reload(["Index"]);
              this.getOrderList();
            })
            .catch((error) => {
              this.$message.error("unfollow order error");
            });
        })
        .catch(() => {});
    },
    //新增订单更新前端渲染
    afterCreateOrder() {
      this.form.currentPage = 1;
    },
    linkToOrder(data) {
      this.$router.push({
        path: `/order/orderDetails`,
        query: {
          orderId: data.orderId
        },
      });
      window.localStorage.setItem("currentPath", "/order");
    },
    //更新订单
    editOrder(item) {
      this.action = "edit";
      this.editContent = item;
      this.$refs.createOrderDialog.open();
    },
    //删除订单操作
    deleteOrder(item) {
      this.deleteOrderShow = true;
      this.deleteInfo = item;
    },
    handleDownload() {
      if(!this.multipleSelection.length) {
        this.$message.warning(this.$t('Please select the data to operate on'));
        return;
      }
      var header = [
        ...(this.form.customNames ? this.form.customNames.split(",") : []),
        this.companyCurrentType == '1' ? 
        this.$t('order.Purchaser') : 
        this.companyCurrentType == '0' ? 
        this.$t('home.VENDOR') : this.$t('order.Supplier'),
        this.$t('order.Please select country'),
        "ci",
        this.$isOrderPITitle(),
        this.$t('home.Currency'),
        this.$t('order.Amount'),
        "createTime",
        this.$t('order.Status'),
      ];
      var filterVal = [
        ...(this.form.customNames ? this.form.customNames.split(",") : []),
        "customerAliasName",
        "country",
        "ci",
        "pi",
        "currency",
        "amount",
        "createTime",
        "progress",
      ];
      if(this.$store.state.user.orderAlias != 0) {
        header = header.filter(item => item != 'ci')
        filterVal = filterVal.filter(item => item != 'ci')
      }
      var filename = this.$t('home.Order');
      var data = this.formatJson(filterVal, this.multipleSelection);
      this.$export_json_to_excel({
        header,
        data,
        filename,
      });
    },
    formatJson(filterVal, tableData) {
      var filterVals = [
        "customerAliasName",
        "country",
        "ci",
        "pi",
        "currency",
        "amount",
        "createTime",
        "progress",
      ];
      return tableData.map((v) => {
        return filterVal.map((j) => {
          if(j == 'country') {
            return this.$isFindData(this.country, v[j], 'value', 'key')
          } else if(j == 'progress') {
            return this.allNodeList.find(item => item.type == v.progress).name
          } else if(!filterVals.includes(j)) {
            if(this.customListComputed(v, j)) {
              if(this.customListComputed(v, j).customType == 1) {
                return JSON.parse(this.customListComputed(v, j).customOptions).find(val => val.value == this.customListComputed(row, item).customValue).label
              } else if(this.customListComputed(v, j).customType == 2) {
                return (this.customListComputed(v, j).customValue ? this.customListComputed(v, j).customValue.split(",") : []).map(xm => JSON.parse(this.customListComputed(v, j).customOptions).find(val => val.value == xm) && JSON.parse(this.customListComputed(v, j).customOptions).find(val => val.value == xm).label).join(",")
              } else if(this.customListComputed(v, j).customType == 3 || this.customListComputed(v, j).customType == 4) {
                return this.customListComputed(v, j).customValue.split(",").filter(item => item).length < 2 ? this.customListComputed(v, j).customValue.split(",")[0] : this.customListComputed(v, j).customValue.split(",").join(" - ")
              } else if(this.customListComputed(v, j).customType == 9) {
                return JSON.parse(this.customListComputed(v, j).customValue) ? JSON.parse(this.customListComputed(v, j).customOptions).open || this.$t('Open') : JSON.parse(this.customListComputed(v, j).customOptions).close || this.$t('Close')
              } else {
                return this.customListComputed(v, j).customValue;
              }
            }
          } else {
            return v[j];
          }
        });
      });
    },
    sortChange(data) {
      const { order, prop } = data;
      if(order) {
        this.form.sortField = prop;
        this.form.sortType = order == "ascending" ? "asc" : "desc";
      } else {
        this.form.sortType = "";
        this.form.sortField = "";
      }
      this.getOrderList(true);
    },
  },
};
</script>

<style lang="less" scoped>
.batchOrderBtn {
  border: 1px solid #A3B0C6;
  border-radius: 4px;
  /deep/ .el-button-group {
    .el-button {
      border: none;
      &:nth-child(1) {
        padding: 9px 12px !important;
      }
    }
  }
}
/deep/ .deletePasswordStyle {
  .el-input {
    .el-input__suffix {
      right: 10px;
      i {
        color: #757D8A;
      }
    }
  }
}
.inside_box {
  padding: 20px 0;
  .inside_box_search {
    display: flex;
    align-items: center;
    gap: 0 14px;
    padding: 0 20px;
  }
}
.selectSwiperBox /deep/ {
  height: 153px;
  width: 100%;
  background: #F7F9FC;
  /deep/.el-carousel__arrow {
    background: #ffffff;
    i {
      color: #757D8A;
      font-size: 16px;
      font-weight: bold;
    }
  }
  .el-carousel__item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    .userItem {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 64px;
      position: relative;
      .name {
        font-size: 14px;
        font-weight: 600;
        color: #122545;
        width: 100%;
        text-align: center;
        margin-top: 10px;
      }
      .el-icon-error {
        font-size: 20px;
        color: #A3B0C6;
        cursor: pointer;
        position: absolute;
        top: -3px;
        right: 1px;
      }
    }
  }
}
.inviteBox {
  height: calc(100vh - 357px);
  overflow: overlay;
  margin-top: 24px;
  padding-bottom: 16px;
  .item {
    padding: 6px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: all .3s;
    &:hover {
      background: #F7F9FC;
    }
    .content {
      flex: 1;
      min-width: 0;
      overflow: hidden;
      margin-left: 10px;
      .name {
        font-size: 14px;
        line-height: 20px;
        color: #122545;
        font-weight: 500;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 4px;
      }
      .job {
        font-size: 12px;
        line-height: 17px;
        color: #757D8A;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
.selectTotal {
  font-size: 12px;
  font-weight: 500;
  color: #637381;
}
</style>
